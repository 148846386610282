import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import MetaWp from "../components/meta-wp"
import Hero from "../components/hero"
import Intro from "../components/intro"
import TextSections from "../components/text-sections"
import Numbers from "../components/numbers"
import AboutBlurbs from "../components/about-blurbs"

const GapfishPage = ({ data, pageContext }) => {
  const {
    metaDefaults,
    metaWpGlobal,
    metaWpPage,
    hideFooterSections,
    hero,
    intro,
    textSections,
    solutionsNumbers,
    aboutBlurbs,
    siteMessage,
  } = data
  const { translations, menus } = pageContext

  return (
    <Layout
      translations={translations}
      menus={menus}
      metaDefaults={metaDefaults}
      metaWpGlobal={metaWpGlobal}
      hideFooterSections={hideFooterSections.acf}
      siteMessage={siteMessage}
    >
      <MetaWp page={metaWpPage} global={metaWpGlobal} defaults={metaDefaults} />

      <Hero data={hero} />

      <Intro data={intro} />

      <TextSections data={textSections} />

      <Numbers data={solutionsNumbers} />

      <AboutBlurbs data={aboutBlurbs} />
    </Layout>
  )
}

GapfishPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default GapfishPage

export const pageQuery = graphql`
  query ($id: String!) {
    ...MetaDefaultsFragment
    ...MetaWpGlobalFragment
    ...MetaWpPageFragment
    ...SiteMessageFragment
    ...HideFooterSectionsPageFragment

    ...HeroPageFragment
    ...IntroPageFragment
    ...TextSectionsPageFragment
    ...SolutionsNumbersPageFragment
    ...AboutBlurbsPageFragment
  }
`
